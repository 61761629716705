import React from 'react'

const TriangleUp: React.FC = () => (
  <svg
    width="11px"
    height="8px"
    viewBox="0 0 11 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-255.000000, -18.000000)" fill="#2A77C7">
        <g>
          <path d="M261.324042,19.1986064 L265.461506,25.2167356 C265.617948,25.4442885 265.560302,25.7555783 265.332749,25.912021 C265.2494,25.9693235 265.150632,26 265.049485,26 L255.950515,26 C255.674373,26 255.450515,25.7761424 255.450515,25.5 C255.450515,25.3988532 255.481192,25.3000848 255.538494,25.2167356 L259.675958,19.1986064 C259.988843,18.7435006 260.611423,18.6282081 261.066529,18.9410933 C261.167405,19.0104456 261.25469,19.0977304 261.324042,19.1986064 Z" />
        </g>
      </g>
    </g>
  </svg>
)

TriangleUp.displayName = 'TriangleUpIcon'

export default TriangleUp
