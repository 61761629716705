function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgWarning = function SvgWarning(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16"
  }, props), React.createElement("defs", null, React.createElement("path", {
    id: "warning_svg__a",
    d: "M.444 14.222h15.112L8 .89.444 14.222zm8.445-1.778H7.11v-1.777H8.89v1.777zm0-2.666H7.11V6.222H8.89v3.556z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "warning_svg__b",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#warning_svg__a"
  })), React.createElement("g", {
    fill: "CurrentColor",
    mask: "url(#warning_svg__b)"
  }, React.createElement("path", {
    d: "M0 0h16v16H0z"
  }))));
};

export default SvgWarning;