function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgErrorOutline = function SvgErrorOutline(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16"
  }, props), React.createElement("defs", null, React.createElement("path", {
    id: "error-outline_svg__a",
    d: "M7.333 10h1.334v1.333H7.333V10zm0-5.333h1.334v4H7.333v-4zm.66-3.334A6.663 6.663 0 001.333 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0014.667 8a6.67 6.67 0 00-6.674-6.667zm.007 12A5.332 5.332 0 012.667 8 5.332 5.332 0 018 2.667 5.332 5.332 0 0113.333 8 5.332 5.332 0 018 13.333z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "error-outline_svg__b",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#error-outline_svg__a"
  })), React.createElement("g", {
    fill: "CurrentColor",
    mask: "url(#error-outline_svg__b)"
  }, React.createElement("path", {
    d: "M0 0h16v16H0z"
  }))));
};

export default SvgErrorOutline;