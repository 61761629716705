import React from 'react'

const House: React.FC = () => (
  <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Fleet-status"
        transform="translate(-136.000000, -147.000000)"
        className="rn-iconfill"
        fillRule="nonzero"
      >
        <path d="M153.774485,154.806454 C154.100777,154.476965 154.069269,153.971956 153.704133,153.678777 L145.659707,147.215913 C145.294571,146.922735 144.709169,146.928876 144.35172,147.229759 L136.279951,154.023351 C135.922502,154.324234 135.904958,154.828596 136.241108,155.149412 L136.443479,155.342925 C136.779218,155.663741 137.321905,155.701991 137.654828,155.428099 L138.258067,154.932087 L138.258067,162.193785 C138.258067,162.639318 138.65125,163 139.136141,163 L142.282539,163 C142.767431,163 143.160613,162.639318 143.160613,162.193785 L143.160613,157.113527 L147.173879,157.113527 L147.173879,162.193785 C147.166896,162.638995 147.513843,162.999677 147.998734,162.999677 L151.333128,162.999677 C151.818019,162.999677 152.211202,162.638995 152.211202,162.193462 L152.211202,155.034339 C152.211202,155.034339 152.377839,155.168376 152.583378,155.334252 C152.788565,155.499805 153.219535,155.367061 153.545827,155.037248 L153.774485,154.806454 Z" />
      </g>
    </g>
  </svg>
)

House.displayName = 'HouseIcon'

export default House
