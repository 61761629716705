function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgKeyboardArrowRight = function SvgKeyboardArrowRight(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16"
  }, props), React.createElement("defs", null, React.createElement("path", {
    id: "keyboard-arrow-right_svg__a",
    d: "M5.727 10.893l3.053-3.06-3.053-3.06.94-.94 4 4-4 4z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "keyboard-arrow-right_svg__b",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#keyboard-arrow-right_svg__a"
  })), React.createElement("g", {
    fill: "CurrentColor",
    mask: "url(#keyboard-arrow-right_svg__b)"
  }, React.createElement("path", {
    d: "M0 0h16v16H0z"
  }))));
};

export default SvgKeyboardArrowRight;