function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgSortDescending = function SvgSortDescending(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 9 14"
  }, props), React.createElement("path", {
    d: "M8.85 9.63l-3.74 3.74a.4.4 0 01-.56 0L.8 9.63a.4.4 0 010-.57l.78-.78a.4.4 0 01.57 0l2.68 2.68L7.5 8.28a.4.4 0 01.56 0l.78.78a.4.4 0 010 .57z"
  }), React.createElement("path", {
    d: "M.8 4.03L4.55.28a.4.4 0 01.56 0l3.74 3.75a.4.4 0 010 .56l-.78.78a.4.4 0 01-.56 0L4.83 2.7 2.15 5.37a.4.4 0 01-.57 0L.8 4.6a.4.4 0 010-.56z",
    fill: "#748999"
  }));
};

export default SvgSortDescending;