enum ALERT_VARIANT {
  DANGER = 'danger',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export {
  ALERT_VARIANT,
}
