import React from 'react'

const Search: React.FC = () => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1693.000000, -148.000000)"
        className="rn-iconfill"
      >
        <g id="Group-18" transform="translate(1429.000000, 135.000000)">
          <g id="Group-17">
            <path
              d="M278.602989,25.3175 L275.325298,21.9914716 C275.802085,21.1014564 276.048672,20.1036479 276.04164,19.0915499 C276.04164,17.4757123 275.407289,15.9267715 274.278564,14.7842172 C273.149283,13.6417842 271.617605,13 270.02082,13 C268.424185,13 266.892477,13.6417387 265.763676,14.7842172 C264.634396,15.9266501 264,17.4755606 264,19.0915499 C264,20.7067806 264.634351,22.2557214 265.763676,23.3982758 C266.892372,24.5407087 268.424035,25.1824929 270.02082,25.1824929 C271.109105,25.1830861 272.176907,24.8820643 273.107628,24.312031 L276.347232,27.5893661 C276.597928,27.8518715 276.942926,28 277.303144,28 C277.663361,28 278.008359,27.8518624 278.259055,27.5893661 L278.64154,27.2024267 L278.640954,27.2030194 C279.134146,26.6673598 279.117157,25.8324398 278.602881,25.3174712 L278.602989,25.3175 Z M270.001582,23.1422174 L270.002168,23.1422174 C268.947861,23.1422174 267.937399,22.7191295 267.191862,21.9654107 C266.446326,21.211692 266.026849,20.1895367 266.026264,19.1235875 C266.025678,18.0575928 266.443297,17.0347701 267.187184,16.279944 C267.931641,15.5250268 268.941413,15.1001793 269.99569,15.0983837 C271.049413,15.0966114 272.06091,15.518504 272.807196,16.2704631 C273.554007,17.0229987 273.975148,18.0445168 273.977497,19.1106177 C273.979829,20.1760208 273.565133,21.1982216 272.823015,21.9542612 C272.081482,22.70977 271.073434,23.1369991 270.020806,23.1423236 L270.001582,23.1422174 Z"
              id="Fill-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Search.displayName = 'SearchIcon'

export default Search
