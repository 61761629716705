enum NOTIFICATION_ARROW_POSITION {
  LEFT_BOTTOM = 'left_bottom',
  TOP_RIGHT = 'top_right',
}

enum NOTIFICATION_PLACEMENT {
  BELOW = 'below',
  RIGHT = 'right',
}

const NOTIFICATION_PLACEMENT_ARROW_POSITION_MAP = {
  [NOTIFICATION_PLACEMENT.BELOW]: NOTIFICATION_ARROW_POSITION.TOP_RIGHT,
  [NOTIFICATION_PLACEMENT.RIGHT]: NOTIFICATION_ARROW_POSITION.LEFT_BOTTOM,
}

const NOTIFICATION_CONTAINER_WIDTH = 335

export {
  NOTIFICATION_ARROW_POSITION,
  NOTIFICATION_PLACEMENT,
  NOTIFICATION_PLACEMENT_ARROW_POSITION_MAP,
  NOTIFICATION_CONTAINER_WIDTH,
}
