function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgButtonConfirm = function SvgButtonConfirm(props) {
  return React.createElement("svg", _extends({
    viewBox: "0 0 6 8"
  }, props), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("path", {
    d: "M-5-4h16v16H-5z"
  }), React.createElement("path", {
    d: "M2.308.276L5.692 3.66a.3.3 0 010 .424L2.308 7.468A.942.942 0 01.976 6.136L3.24 3.872.976 1.608A.942.942 0 012.308.276z",
    fill: "#FFF"
  })));
};

export default SvgButtonConfirm;