function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgCheckBox = function SvgCheckBox(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16"
  }, props), React.createElement("defs", null, React.createElement("path", {
    id: "check-box_svg__a",
    d: "M12.667 2H3.333C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.593 14 3.333 14h9.334c.74 0 1.333-.6 1.333-1.333V3.333C14 2.6 13.407 2 12.667 2zm-6 9.333L3.333 8l.94-.94 2.394 2.387 5.06-5.06.94.946-6 6z"
  })), React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("mask", {
    id: "check-box_svg__b",
    fill: "#fff"
  }, React.createElement("use", {
    xlinkHref: "#check-box_svg__a"
  })), React.createElement("g", {
    fill: "CurrentColor",
    mask: "url(#check-box_svg__b)"
  }, React.createElement("path", {
    d: "M0 0h16v16H0z"
  }))));
};

export default SvgCheckBox;