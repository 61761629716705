enum FLOATING_BOX_SCHEME {
  LIGHT = 'light',
  DARK = 'dark'
}

enum FLOATING_BOX_ARROW_POSITION {
  LEFT_BOTTOM = 'left_bottom',
  LEFT_TOP = 'left_top',
  RIGHT_BOTTOM = 'right_bottom',
  RIGHT_TOP = 'right_top',
  TOP_LEFT = 'top_left',
  TOP_RIGHT = 'top_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right'
}

export {
  FLOATING_BOX_SCHEME,
  FLOATING_BOX_ARROW_POSITION,
}
